<template>
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/">Dashboard</router-link>
        </li>
        <li class="nav-item"  v-if="['Master','Administrativo'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/lojas">Lojas</router-link>
        </li>
        <li class="nav-item" v-if="['Master','Administrativo'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/usuarios">Usuários</router-link>
        </li>
        <li class="nav-item"  v-if="['Master','Administrativo'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/contagens">Contagens</router-link>
        </li>
        
        <li class="nav-item"  v-if="['Master','Administrativo'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/importacao-notas">Importação de Notas</router-link>
        </li>

        <li class="nav-item"  v-if="['Master','Administrativo'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/recebimento-produtos">Recebimento de Produtos</router-link>
        </li>
    
        <li class="nav-item"  v-if="['Master','Administrativo','Auditor'].includes(user.tipo)">
            <router-link class="nav-link" active-class="active" aria-current="page" to="/produtos">Produtos</router-link>
        </li>
    
    </ul>
</template>
<script>
    export default {
        computed: {
            user() {
                return this.$store.state.user;
            }
        }
    }
</script>